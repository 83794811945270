<template>
  <v-app>
    <v-container>
      <!-- header -->
      <h4>Tiêu đề câu hỏi</h4>
      <v-row>
        <v-col cols="12">
          <CkContent
              validateName="question"
              :content="question.content"
              @getData="(value) => question.content = value"
          ></CkContent>
        </v-col>
      </v-row>
      <h4>Bản dịch</h4>
      <v-row>
        <v-col cols="12">
          <CkContent
              validateName="question"
              :content="question.content_translate"
              @getData="(value) => question.content_translate = value"
          ></CkContent>
        </v-col>
      </v-row>

      <!-- select block -->
      <v-row class="pl-2">
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <v-col v-if="isSelectBox" class="justify-center d-flex">
            <v-radio-group v-model="question.question_type" row>
              <v-radio label="Chọn đáp án là ảnh" :value="1"></v-radio>
              <v-radio label="Chọn đáp án là text" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-col>
      </v-row>
      <!-- main -->
      <!-- select answer block -->
      <v-row>
        <v-col cols="10">
          <h6>Nhập đáp án</h6>
        </v-col>
        <v-col cols="2">
          <v-btn color="black" class="white--text" @click="addAnswer"
          >Thêm đáp án
          </v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-row v-if="question.question_type == 1">
            <v-col cols="12">
              <v-data-table
                  :headers="headers1"
                  :items="question.answers"
                  :items-per-page="10"
                  class="elevation-1 text-center"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:item.check="{ item }">
                  <v-checkbox v-model="item.check"></v-checkbox>
                </template>
                <template v-slot:item.file="{ item }">
                  <v-img :src="item.url" width="100px"></v-img>
                  <v-file-input
                      name="image"
                      class="pl-1"
                      prepend-icon="mdi-camera"
                      v-model="item.file"
                      label="Tải ảnh"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      @change="onChangeFileUpload(item.id)"
                      @click:clear="clearImage"
                  ></v-file-input>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="question.question_type == 2">
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="question.answers"
                  :items-per-page="10"
                  class="elevation-1 text-center"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:item.check="{ item }">
                  <v-checkbox v-model="item.check"></v-checkbox>
                </template>
                <template v-slot:item.file="{ item }">
                  <v-text-field dense v-model="item.content"></v-text-field>
                </template>
                <template v-slot:item.description="{ item }">
                  <v-text-field dense v-model="item.description"></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- footer -->
      <v-row>
        <v-col class="text-center justify-center">
          <div class="my-4">
            <v-btn
                class="ma-2 white--text"
                color="blue-grey"
                @click="$router.go(-1)"
            >Hủy
            </v-btn
            >
            <v-btn color="primary" type="submit" @click="submitQuestion"
            >Lưu
            </v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import {
  SAVE_EXERCISE_CONTENT,
  GET_EXERCISE_DETAIL,
  GET_QUESTION_DETAIL,
  UPDATE_EXERCISE_CONTENT,
} from "@/store/exercises.module";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {mapGetters} from "vuex";
import CkContent from "@/components/ck-content";

export default {
  components: {
    CkContent,
  },
  data: () => ({
    newIndex: -1,
    headers: [
      {text: "Đáp án", value: "file"},
      {text: "Dịch đáp án", value: "description"},
      {text: "Chọn đáp án đúng", value: "check"},
      {text: "Thao tác", value: "actions", sortable: false},
    ],
    headers1: [
      {text: "Đáp án", value: "file"},
      {text: "Chọn đáp án đúng", value: "check"},
      {text: "Thao tác", value: "actions", sortable: false},
    ],
    writeAnswer: 0,
    isSelectBox: true,
    isUpdate: false,
    imageError: "",
    show: false,
    images: [],
    img: ''
  }),
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      question: "questionDetail",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_QUESTION_DETAIL, {
      question_id: this.$route.query.question_id,
    });
    if (this.$route.query.question_id) {
      this.isSelectBox = false;
      this.isUpdate = true;
    }
  },
  watch: {},
  methods: {
    onChangeFileUpload(index) {
      this.question.answers.map(answer => {
        if (answer.id == index) {
          let img = answer.file
          this.imageError = "";
          let formData = new FormData();
          formData.append("upload", img);
          this.$store.dispatch(UPLOAD_IMAGE, formData).then(res => {
            if (res.data.status == 1) {
              answer.url = res.data.data
              this.$forceUpdate();
            } else {
              this.$toasted.error('File chưa đúng định dạng', {
                position: "top-right",
                duration: 3000
              })
            }
          });
        }
      })
    },
    clearImage() {
      this.feedback.image_src = "";
      this.show = false;
    },
    addAnswer() {
      this.question.answers.push({
        id: --this.newIndex,
        file: null,
        content: null,
        url: null,
        check: 0,
      });
    },
    deleteItem(item) {
      const index = this.question.answers.indexOf(item);
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.question.answers.splice(index, 1);
    },
    getMissingSentenceContent(value) {
      this.question.filled_dialog.missing_sentence = value;
    },
    getCompleteSentenceContent(value) {
      this.question.filled_dialog.complete_sentence = value;
    },
    countRightAnswer(answers) {
      let count = 0;
      answers.forEach((item) => {
        if (item.check) {
          count++;
        }
      })
      return count;
    },
    submitQuestion() {
      if (!this.question.content) {
        this.$toasted.error('Bạn chưa nhập câu hỏi', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (!this.question.content_translate) {
        this.$toasted.error('Bạn chưa nhập bản dịch', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (this.question.answers.length == 0) {
        this.$toasted.error('Bạn chưa nhập câu trả lười', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      let check = 0
      this.question.answers.map(item => {
        if (this.question.question_type == 1) {
          if (item.url) {
            check++
          }
        }
        if (this.question.question_type == 2) {
          if (item.content) {
            check++
          }
        }
      })

      if (check != this.question.answers.length) {
        this.$toasted.error('Các câu trả lời chưa đầy đủ thông tin', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (this.question.question_type == 2) {
      let check1 = 0
      this.question.answers.map(item => {
          if (item.description) {
            check1++
          }
      })

      if (check1 != this.question.answers.length) {
        this.$toasted.error('Các câu trả lời chưa đầy đủ thông tin', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      }
      if (this.countRightAnswer(this.question.answers) < 1) {
        this.$toasted.error('Bạn chưa chọn câu trả lời đúng', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (check && this.question.content) {
        // get type of question
        this.question.type = this.writeAnswer;
        // fill diaglog convert to array
        let filled_dialog = [];
        filled_dialog.push(this.question.filled_dialog);
        if (this.isUpdate) {
          this.$store
              .dispatch(UPDATE_EXERCISE_CONTENT, {
                question: this.question,
                answers: this.question.answers,
                exercise: this.exercise,
              })
              .then((data) => {
                if (data.data.status) {
                  this.$router.go(-1);
                }
              });
        }
        if (!this.isUpdate) {
          this.$store
              .dispatch(SAVE_EXERCISE_CONTENT, {
                question: this.question,
                answers: this.question.answers,
                exercise: this.exercise,
              })
              .then((data) => {
                if (data.data.status) {
                  this.$router.go(-1);
                }
              });
        }
      }

    },
  },
};
</script>
